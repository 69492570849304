import { defineStore } from "pinia/dist/pinia";
import { ProductDetail } from "@/heplers/classes/productDetail/ProductDetail";

export const useKycFormStore = defineStore("kycFormStore", {
  // initial states of kyc form fields, form related support fields
  state: () => ({
    ApplicationData: "",
    productSelection: "",
    buttonState:false,
    formData: {
      landing_token: "",
      selLang: "",
      title: "",
      calling_name: "",
      full_name: "",
      first_name: "",
      citizenship: "",
      citizenship_1: "",
      residentship: "",
      mobileNumber: "",
      email: "",
      secondary_email: "",
      idType: "",
      customer_nic: "",
      customer_passport_no: "",
      age: "",
      countryCode: "",
      country_short_name: "",
      contact_no: "",

      resident_country_short_name: "",
      resident_country_code: "",
      residentNumber: "",
      contact_no_resident: "",
      officeNumber: "",
      contact_no_work: "",
      telephone_no: "",
      office_country_code: "",
      office_country_short_name: "",

      mail_otp_not_receive: "",
      dashboard_otp_not_receive: "",
      otp_not_receive: false,

      account_type: true,

      nicNumberAllowToDRP: false,
      voiceAllow: false,
      digitalFootprintAllow: false,
      nicNumberAllowToLocationServicesInYourBrowser: false,
      isChangeMobile: false,
      mobileNumberAndEmailEditRoute: "",
      isChangeEmail: false,

      nic_issue_date: null,
      date_of_birth: new Date(),
      passport_expiry_date: null,
      passport_issue_date: null,
      gender: "",
      marital_status: "",
      correspondence_address_differ_with_permanent_address: false,
      permanent_address_line1: "",
      permanent_address_line2: "",
      permanent_address_line3: "",
      permanent_address_town: "",
      permanent_address_postal_code: "",
      permanent_address_district: "",
      permanent_address_country: "",
      correspondence_address_differ: "",
      correspondent_address_line1: "",
      correspondent_address_line2: "",
      correspondent_address_line3: "",
      street_2:"",
      correspondence_address_street_2:"",
      correspondent_address_town: "",
      correspondent_address_postal_code: "",
      correspondent_address_district: "",
      correspondent_address_country: "",
      status_of_residence: "",
      maiden_name: "",

      employer_name: "",
      employment_status: "",
      nature_of_business: "",
      employer_address_line1: "",
      employer_address_line2: "",
      employer_address_line3: "",
      employer_address_town: "",
      employer_address_postal_code: "",
      employer_address_district: "",
      employer_address_country: "",
      are_you_a_freelancer: '',
      working_platforms: [],
      specified_working_platforms: "",

      // variable to store product selection - stores  ProductDetails Object - initially contains one product detail
      product_selection: [new ProductDetail()],

      purpose_of_opening: [],
      specified_purpose_of_opening: "",

      are_you_a_taxpayer: "",
      tax_file_num: "",

      politically_exposed: "",
      politically_exposed_name: "",
      politically_exposed_position: "",
      second_politically_exposed_name: "",
      second_politically_exposed_position: "",
      politically_exposed_relative: "",
      politically_exposed_relative_position: "",
      second_politically_exposed_relative: "",
      second_politically_exposed_relative_position: "",

      source_of_income: [],
      specified_source_of_income: "",
      anticipated_vol: "",

      branch: "",
      currency_type: "",
      reason_for_opening_an_account: "",
      saving_account_type: "",
      initial_deposit: "",

      preferred_date_for_video_call: new Date(),
      preferred_time_for_video_call: "",
      customer_declaration: "",
      customer_location: null,

      sms_service: true,
      e_banking_service: true,
      virtual_wallet_service: true,
      estatement_service: true,
      debit_card_service: true,
      pos_required: true,
      collection_details: "",
      pin_collection_details: "",
      facility_debit_card_customer_name: "",

      email_otp_not_receive: false,

      second_currency_type: "",
      second_saving_account_type: "",
      second_purpose_of_opening: "",
      second_specified_purpose_of_opening: "",
      second_anticipated_vol: "",
      second_initial_deposit: "",
      third_currency_type: "",
      third_saving_account_type: "",
      third_purpose_of_opening: "",
      third_specified_purpose_of_opening: "",
      third_anticipated_vol: "",
      third_initial_deposit: "",
      account_to_be_linked_for_pos_transaction: "",
      account_count: 1,

      previousPageName : "",
      customer_nic_eic_pp: "",
      otp_status_know: "",
      terms_conditions: "",
      read_terms_condition1: "",
      read_terms_condition2: "",

      // to track verification details
      verified_data: {
        otp: {
          verified: false,
          verified_mobile_number: "",
          verified_email: "",
        },
        nic_eic: "",
        passport: "",
      },
      preUploadedAttachmentTypes:{
        nic_front_image: "",
        nic_back_image: "",
        selfie_image: "",
        sign_image: "",
        addr_proof_doc: "",
        passport_image: "",
        passport_image_2: "",
        visa_proof_doc: "",
        stamp_page_image: "",
        visa_image: "",
      },
      preUploadedImages: {
        nic_front_image: "",
        nic_back_image: "",
        selfie_image: "",
        sign_image: "",
        addr_proof_doc: "",
        passport_image: "",
        passport_image_2: "",
        visa_proof_doc: "",
        stamp_page_image: "",
        visa_image: "",
      },
      nic_face_detection_details: null,
      selfie_face_detection_details: null,

    },
    detailPDF: {
      viewDetailPdf: null,
    },

    productImages: {
      nic_front_image: null,
      nic_back_image: null,
      selfie_image: null,
      sign_image: null,
      addr_proof_doc: null,
      passport_image: null,
      passport_image_2: null,
      visa_proof_doc: null,
      stamp_page_image: null,
      visa_image: null,
    },

    previousProductImages: {
      nic_front_image: null,
      nic_back_image: null,
      selfie_image: null,
      sign_image: null,
      addr_proof_doc: null,
      passport_image: null,
      passport_image_2: null,
      visa_proof_doc: null,
      stamp_page_image: null,
      visa_image: null,
    },

    imageURLs: {
      nic_front_image: "",
      nic_back_image: "",
      selfie_image: "",
      sign_image: "",
      addr_proof_doc: "",
      passport_image: "",
      passport_image_2: "",
      visa_proof_doc: "",
      stamp_page_image: "",
      visa_image: "",
    },

    formDataSupport: {
      countriesArray: [],
      districtsArray: [],
      branchesArray: [],
      selectedAccountArray: [],
      isCountdownStarted: false,
      otpLength: 4,
      maxProducts: 3,
      productList: [],
    },

    visitfromKycForm: false,
    isDateOfBirthSet: false,
    isGenderSet: false,
    isNicChange: false,
    kyc_reference_no: null,
    kyc_form_data: {
      videoCallDetail:null,
      application: null,
      customer_branch: null,
      agent_branch:null,
      products: null,
    },

    // selfie liveness result
    selfieLivenessResult : null,
    selfieLivenessVideoBase64Url: null,
   
  }),
});
